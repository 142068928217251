import React from 'react';

const Me = () => {

    const handleScroll = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <div style={{ height: '100vh' }} className='me-container'>
            <h1>Welcome to my Portfolio</h1>
            <h3>
                This portfolio website gives a brief description of my skillset and showcases some of the projects I've worked on. I am a back-end software engineer with 2 years experience as a programmer. Tools used include Python, Django, Java, SQL, MongoDB, AWS Cloud, ReactJS, Git, Docker.
            </h3>
            <button onClick={() => handleScroll('projects')}>Latest Projects</button>
        </div>
    );
};

export default Me;
